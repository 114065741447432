/* eslint-disable */
import { createAxiosInstance, RequestConfig } from "@uxf/cms/lib/api";

const { axiosRequest, useAxiosRequest } = createAxiosInstance();

// App\AdminZone\Core\Enum\EntityAlias
export enum EntityAlias {
    ArticleCategory = 'article-category',
}

// App\AdminZone\Core\Enum\EntityNames
export enum EntityNames {
}

// 
export enum UserRole {
    RoleAdmin = 'ROLE_ADMIN',
    RoleRoot = 'ROLE_ROOT',
    RoleUser = 'ROLE_USER',
}

// App\CoreZone\Enum\DataGridColumnType
export enum DataGridColumnType {
    ExternalLink = 'external-link',
    MealType = 'meal-type',
    MealName = 'meal-name',
    MealUnit = 'meal-unit',
    Allergens = 'allergens',
    Price = 'price',
    Quantity = 'quantity',
    Date = 'date',
    OrderItemState = 'order-item-state',
    RestaurantCreditJournalState = 'restaurant-credit-journal-state',
    RestaurantReportState = 'restaurant-report-state',
}

// App\OrderZone\Enum\OrderItemState
export enum OrderItemState {
    Ordered = 'ORDERED',
    PaymentStarted = 'PAYMENT_STARTED',
    PaymentFailed = 'PAYMENT_FAILED',
    Paid = 'PAID',
    Delivered = 'DELIVERED',
    CanceledByUserBeforeDeadline = 'CANCELED_BY_USER_BEFORE_DEADLINE',
    CanceledByUserAfterDeadline = 'CANCELED_BY_USER_AFTER_DEADLINE',
    CanceledByRestaurantBeforeDeadline = 'CANCELED_BY_RESTAURANT_BEFORE_DEADLINE',
    CanceledByRestaurantAfterDeadline = 'CANCELED_BY_RESTAURANT_AFTER_DEADLINE',
    RefundedExpiredOrder = 'REFUNDED_EXPIRED_ORDER',
}

// App\OrderZone\Enum\PaymentState
export enum PaymentState {
    Started = 'STARTED',
    Paid = 'PAID',
    Canceled = 'CANCELED',
}

// App\OrderZone\Enum\RestaurantCreditJournalType
export enum RestaurantCreditJournalType {
    MealPayment = 'MEAL_PAYMENT',
    CancellationFee = 'CANCELLATION_FEE',
    CancellationBonus = 'CANCELLATION_BONUS',
    Other = 'OTHER',
}

// App\OrderZone\Enum\VoucherState
export enum VoucherState {
    InvalidToken = 'INVALID_TOKEN',
    Valid = 'VALID',
    ValidAfterDeadline = 'VALID_AFTER_DEADLINE',
    ValidPartiallyDelivered = 'VALID_PARTIALLY_DELIVERED',
    ValidFullyDelivered = 'VALID_FULLY_DELIVERED',
    ValidCanceled = 'VALID_CANCELED',
}

// App\RestaurantZone\Enum\MealUnit
export enum MealUnit {
    Gram = 'GRAM',
    Piece = 'PIECE',
    Milliliter = 'MILLILITER',
    Liter = 'LITER',
}

// App\RestaurantZone\Enum\RestaurantProfileRole
export enum RestaurantProfileRole {
    Admin = 'ADMIN',
    Service = 'SERVICE',
}

// App\RestaurantZone\Enum\RestaurantReportState
export enum RestaurantReportState {
    Current = 'CURRENT',
    ForPayment = 'FOR_PAYMENT',
    Paid = 'PAID',
}

// UXF\Content\Entity\VisibilityLevel
export enum VisibilityLevel {
    Public = 'PUBLIC',
    PublicWithoutSitemap = 'PUBLIC_WITHOUT_SITEMAP',
    Private = 'PRIVATE',
}

// 
export enum Currency {
    Aed = 'AED',
    Afn = 'AFN',
    All = 'ALL',
    Amd = 'AMD',
    Ang = 'ANG',
    Aoa = 'AOA',
    Ars = 'ARS',
    Aud = 'AUD',
    Awg = 'AWG',
    Azn = 'AZN',
    Bam = 'BAM',
    Bbd = 'BBD',
    Bdt = 'BDT',
    Bgn = 'BGN',
    Bhd = 'BHD',
    Bif = 'BIF',
    Bmd = 'BMD',
    Bnd = 'BND',
    Bob = 'BOB',
    Bov = 'BOV',
    Brl = 'BRL',
    Bsd = 'BSD',
    Btn = 'BTN',
    Bwp = 'BWP',
    Byn = 'BYN',
    Bzd = 'BZD',
    Cad = 'CAD',
    Cdf = 'CDF',
    Che = 'CHE',
    Chf = 'CHF',
    Chw = 'CHW',
    Clf = 'CLF',
    Clp = 'CLP',
    Cny = 'CNY',
    Cop = 'COP',
    Cou = 'COU',
    Crc = 'CRC',
    Cuc = 'CUC',
    Cup = 'CUP',
    Cve = 'CVE',
    Czk = 'CZK',
    Djf = 'DJF',
    Dkk = 'DKK',
    Dop = 'DOP',
    Dzd = 'DZD',
    Egp = 'EGP',
    Ern = 'ERN',
    Etb = 'ETB',
    Eur = 'EUR',
    Fjd = 'FJD',
    Fkp = 'FKP',
    Gbp = 'GBP',
    Gel = 'GEL',
    Ghs = 'GHS',
    Gip = 'GIP',
    Gmd = 'GMD',
    Gnf = 'GNF',
    Gtq = 'GTQ',
    Gyd = 'GYD',
    Hkd = 'HKD',
    Hnl = 'HNL',
    Htg = 'HTG',
    Huf = 'HUF',
    Idr = 'IDR',
    Ils = 'ILS',
    Inr = 'INR',
    Iqd = 'IQD',
    Irr = 'IRR',
    Isk = 'ISK',
    Jmd = 'JMD',
    Jod = 'JOD',
    Jpy = 'JPY',
    Kes = 'KES',
    Kgs = 'KGS',
    Khr = 'KHR',
    Kmf = 'KMF',
    Kpw = 'KPW',
    Krw = 'KRW',
    Kwd = 'KWD',
    Kyd = 'KYD',
    Kzt = 'KZT',
    Lak = 'LAK',
    Lbp = 'LBP',
    Lkr = 'LKR',
    Lrd = 'LRD',
    Lsl = 'LSL',
    Lyd = 'LYD',
    Mad = 'MAD',
    Mdl = 'MDL',
    Mga = 'MGA',
    Mkd = 'MKD',
    Mmk = 'MMK',
    Mnt = 'MNT',
    Mop = 'MOP',
    Mru = 'MRU',
    Mur = 'MUR',
    Mvr = 'MVR',
    Mwk = 'MWK',
    Mxn = 'MXN',
    Mxv = 'MXV',
    Myr = 'MYR',
    Mzn = 'MZN',
    Nad = 'NAD',
    Ngn = 'NGN',
    Nio = 'NIO',
    Nok = 'NOK',
    Npr = 'NPR',
    Nzd = 'NZD',
    Omr = 'OMR',
    Pab = 'PAB',
    Pen = 'PEN',
    Pgk = 'PGK',
    Php = 'PHP',
    Pkr = 'PKR',
    Pln = 'PLN',
    Pyg = 'PYG',
    Qar = 'QAR',
    Ron = 'RON',
    Rsd = 'RSD',
    Rub = 'RUB',
    Rwf = 'RWF',
    Sar = 'SAR',
    Sbd = 'SBD',
    Scr = 'SCR',
    Sdg = 'SDG',
    Sek = 'SEK',
    Sgd = 'SGD',
    Shp = 'SHP',
    Sle = 'SLE',
    Sll = 'SLL',
    Sos = 'SOS',
    Srd = 'SRD',
    Ssp = 'SSP',
    Stn = 'STN',
    Svc = 'SVC',
    Syp = 'SYP',
    Szl = 'SZL',
    Thb = 'THB',
    Tjs = 'TJS',
    Tmt = 'TMT',
    Tnd = 'TND',
    Top = 'TOP',
    Try = 'TRY',
    Ttd = 'TTD',
    Twd = 'TWD',
    Tzs = 'TZS',
    Uah = 'UAH',
    Ugx = 'UGX',
    Usd = 'USD',
    Usn = 'USN',
    Uyi = 'UYI',
    Uyu = 'UYU',
    Uyw = 'UYW',
    Uzs = 'UZS',
    Ved = 'VED',
    Ves = 'VES',
    Vnd = 'VND',
    Vuv = 'VUV',
    Wst = 'WST',
    Xaf = 'XAF',
    Xcd = 'XCD',
    Xof = 'XOF',
    Xpf = 'XPF',
    Yer = 'YER',
    Zar = 'ZAR',
    Zmw = 'ZMW',
    Zwl = 'ZWL',
}

// UXF\DataGrid\DataGridSortDir
export enum DataGridSortDir {
    Asc = 'asc',
    Desc = 'desc',
}

// App\AdminZone\Mail\Http\Query\RendererMailQuery
export interface AdminRendererMailQuery {
    path: XString;
    variables: XString;
}

// App\OrderZone\Http\Request\VoucherDetailRequest
export interface OrderVoucherDetailRequest {
    uniqueToken: XString;
    restaurant: Int;
}

// App\OrderZone\Http\Response\OrderItemResponse
export interface OrderItemResponse {
    id: Int;
    date: Date;
    restaurant: RestaurantResponse;
    dailyMenuItem: RestaurantDailyMenuItemResponse;
    amount: Int;
    packaging: Bool;
    price: Int;
    pricePackaging: Int;
    totalPrice: Int;
    state: OrderItemState;
    isArchived: Bool;
    deliveredAt: DateTime | null;
}

// App\OrderZone\Http\Response\OrderResponse
export interface OrderResponse {
    id: Int;
    createdAt: DateTime;
    updatedAt: DateTime;
}

// App\OrderZone\Http\Response\PaymentResponse
export interface OrderPaymentResponse {
    id: Int;
    order: OrderResponse | null;
    amount: Int;
    externalPaymentId: XString;
    redirectUrl: XString;
    paymentState: PaymentState;
    createdAt: DateTime;
    updatedAt: DateTime;
}

// App\OrderZone\Http\Response\VoucherDetailResponse
export interface OrderVoucherDetailResponse {
    state: VoucherState;
    date: Date | null;
    restaurant: RestaurantResponse | null;
    orderItems: Array<OrderItemResponse>;
}

// App\ProfileZone\Http\Request\ProfileRequestBody
export interface ProfileRequestBody {
    name: XString;
    surname: XString;
    avatar?: Int | null;
}

// App\ProfileZone\Http\Response\ProfileResponse
export interface ProfileResponse {
    id: Int;
    email: XString;
    name: XString;
    surname: XString;
    avatar: StorageImageResponse | null;
}

// App\RestaurantZone\Http\Request\CountRequestBody
export interface RestaurantCountRequestBody {
    count: Int;
}

// App\RestaurantZone\Http\Request\DailyMenuItemRequestBody
export interface RestaurantDailyMenuItemRequestBody {
    meals: Array<Int>;
}

// App\RestaurantZone\Http\Request\DailyMenuRequestBody
export interface RestaurantDailyMenuRequestBody {
    dailyMenuItems: Array<RestaurantDailyMenuItemRequestBody>;
    dates: Array<Date>;
}

// App\RestaurantZone\Http\Request\MealRequestBody
export interface RestaurantMealRequestBody {
    name: XString;
    mealType: Int;
    isActive: Bool;
    allergens?: Array<Int>;
    description?: XString | null;
    amount?: XString | null;
    unit?: MealUnit | null;
    photo?: Int | null;
    id?: Int | null;
}

// App\RestaurantZone\Http\Request\PriceRequestBody
export interface RestaurantPriceRequestBody {
    price: Int;
}

// App\RestaurantZone\Http\Request\RestaurantProfileRequestBody
export interface RestaurantProfileRequestBody {
    restaurant: Int;
    name: XString;
    surname: XString;
    email: XString;
    restaurantProfileRole: RestaurantProfileRole;
    id?: Int | null;
}

// App\RestaurantZone\Http\Request\RestaurantRequestBody
export interface RestaurantRequestBody {
    name: XString;
    lunchTimeFrom: Time;
    lunchTimeTo: Time;
    isActive: Bool;
    restaurantTags?: Array<Int>;
    description?: XString | null;
    registrationNumber?: XString | null;
    vatNumber?: XString | null;
    isVatPayer?: Bool | null;
    bankAccountNumber?: XString | null;
    bankCode?: XString | null;
    photo?: Int | null;
    smartAddressId?: Int | null;
    address?: XString | null;
    pricePackaging?: Int | null;
    id?: Int | null;
}

// App\RestaurantZone\Http\Request\SearchMealsRequestQuery
export interface RestaurantSearchMealsRequestQuery {
    search?: XString | null;
}

// App\RestaurantZone\Http\Response\DailyMenu\DailyMenuItemGridResponse
export interface RestaurantDailyMenuItemGridResponse {
    id: Int;
    ordered: Int;
    delivered: Int;
    canceled: Int;
    limitOrderCount: Int | null;
    priceApplication: Int | null;
    priceRestaurant: Int | null;
    isActive: Bool;
    itemOrder: Int | null;
    dailyMenuItemMeal: Array<RestaurantDailyMenuItemMealResponse>;
}

// App\RestaurantZone\Http\Response\DailyMenu\DailyMenuItemMealResponse
export interface RestaurantDailyMenuItemMealResponse {
    id: Int;
    mealType: RestaurantMealTypeResponse;
    name: XString;
    description: XString | null;
    amount: XString | null;
    unit: MealUnit | null;
    photo: StorageImageResponse | null;
}

// App\RestaurantZone\Http\Response\DailyMenu\DailyMenuItemResponse
export interface RestaurantDailyMenuItemResponse {
    id: Int;
    limitOrderCount: Int | null;
    priceApplication: Int | null;
    priceRestaurant: Int | null;
    isActive: Bool;
    itemOrder: Int | null;
    dailyMenuItemMeal: Array<RestaurantDailyMenuItemMealResponse>;
}

// App\RestaurantZone\Http\Response\DailyMenu\DailyMenuResponse
export interface RestaurantDailyMenuResponse {
    id: Int;
    restaurant: RestaurantResponse;
    date: Date;
    isPublished: Bool;
    dailyMenuTypes: Array<RestaurantDailyMenuTypeResponse>;
}

// App\RestaurantZone\Http\Response\DailyMenu\DailyMenuTypeResponse
export interface RestaurantDailyMenuTypeResponse {
    id: XString;
    type: XString;
    dailyMenuItems: Array<RestaurantDailyMenuItemGridResponse>;
}

// App\RestaurantZone\Http\Response\Meal\AllergenResponse
export interface RestaurantAllergenResponse {
    id: Int;
    label: XString;
}

// App\RestaurantZone\Http\Response\Meal\MealResponse
export interface RestaurantMealResponse {
    id: Int;
    mealType: RestaurantMealTypeResponse;
    name: XString;
    isActive: Bool;
    allergens: Array<RestaurantAllergenResponse>;
    description: XString | null;
    amount: XString | null;
    unit: MealUnit | null;
    photo: StorageImageResponse | null;
}

// App\RestaurantZone\Http\Response\Meal\MealTypeResponse
export interface RestaurantMealTypeResponse {
    id: Int;
    name: XString;
    order: Int;
    isMainMeal: Bool;
}

// App\RestaurantZone\Http\Response\Report\PrintReportItemResponse
export interface RestaurantPrintReportItemResponse {
    id: XString;
    date: Date;
    name: XString;
    mealPrice: Int;
    totalPrice: Int;
    amount: Int;
    type: RestaurantCreditJournalType;
}

// App\RestaurantZone\Http\Response\Report\PrintReportResponse
export interface RestaurantPrintReportResponse {
    id: XString;
    reportSummaryResponse: RestaurantReportSummaryResponse;
    reportItems: Array<RestaurantPrintReportItemResponse>;
}

// App\RestaurantZone\Http\Response\Report\ReportSummaryResponse
export interface RestaurantReportSummaryResponse {
    id: XString;
    restaurantName: XString;
    dateFrom: Date;
    dateTo: Date;
    price: Int;
    itemsCount: Int;
    reportState: RestaurantReportState;
    variableSymbol: XString | null;
}

// App\RestaurantZone\Http\Response\Report\RestaurantReportItemResponse
export interface RestaurantReportItemResponse {
    id: Int;
    type: RestaurantCreditJournalType;
    totalPrice: Int;
    name: XString | null;
    paidAt: DateTime | null;
    deliveredAt: DateTime | null;
    deliveredBy: XString | null;
    amount: Int | null;
}

// App\RestaurantZone\Http\Response\Report\RestaurantReportItemsResponse
export interface RestaurantReportItemsResponse {
    id: XString;
    restaurantReportItems: Array<RestaurantReportItemResponse>;
}

// App\RestaurantZone\Http\Response\Report\RestaurantReportResponse
export interface RestaurantReportResponse {
    id: XString;
    currentReport: RestaurantReportSummaryResponse;
    createdReports: Array<RestaurantReportSummaryResponse>;
}

// App\RestaurantZone\Http\Response\Restaurant\AddressResponse
export interface RestaurantAddressResponse {
    id: Int;
    obecNazev: XString | null;
    obec: XString | null;
    momcNazev: XString | null;
    mopNazev: XString | null;
    castObceNazev: XString | null;
    uliceNazev: XString | null;
    ulice: XString | null;
    typSo: XString | null;
    cisloDomovni: Int | null;
    cisloOrientacni: Int | null;
    cisloOrientacniZnak: XString | null;
    psc: Int | null;
    search: XString | null;
    level: Int | null;
    gps: RestaurantPointResponse | null;
}

// App\RestaurantZone\Http\Response\Restaurant\MyRestaurantResponse
export interface RestaurantMyRestaurantResponse {
    id: Int;
    name: XString;
    description: XString | null;
    registrationNumber: XString | null;
    vatNumber: XString | null;
    bankAccountNumber: XString | null;
    bankCode: XString | null;
    lunchTimeFrom: Time;
    lunchTimeTo: Time;
    isActive: Bool;
    photo: StorageImageResponse | null;
    address: RestaurantAddressResponse | null;
    myRestaurantRole: RestaurantProfileRole;
}

// App\RestaurantZone\Http\Response\Restaurant\PointResponse
export interface RestaurantPointResponse {
    latitude: Float;
    longitude: Float;
}

// App\RestaurantZone\Http\Response\Restaurant\RestaurantProfileResponse
export interface RestaurantProfileResponse {
    id: Int;
    restaurantId: Int;
    email: XString;
    name: XString;
    surname: XString;
    restaurantProfileRole: RestaurantProfileRole;
}

// App\RestaurantZone\Http\Response\Restaurant\RestaurantResponse
export interface RestaurantResponse {
    id: Int;
    name: XString;
    description: XString | null;
    registrationNumber: XString | null;
    vatNumber: XString | null;
    bankAccountNumber: XString | null;
    bankCode: XString | null;
    lunchTimeFrom: Time;
    lunchTimeTo: Time;
    isActive: Bool;
    isVatPayer: Bool;
    photo: StorageImageResponse | null;
    address: RestaurantAddressResponse | null;
    pricePackaging: Int | null;
    restaurantTags: Array<RestaurantTagResponse>;
}

// App\RestaurantZone\Http\Response\Restaurant\RestaurantTagResponse
export interface RestaurantTagResponse {
    id: Int;
    label: XString;
}

type UUID = string;

// UXF\CMS\Http\Request\Cms\ChangePasswordRequestBody
export interface CMSChangePasswordRequestBody {
    originalPassword: XString;
    newPassword: XString;
    newPasswordAgain: XString;
}

// UXF\CMS\Http\Request\Cms\CmsAutocompleteRequestQuery
export interface CMSCmsAutocompleteRequestQuery {
    term?: XString;
    limit?: Int;
}

// UXF\CMS\Http\Request\Cms\CreateUserRequestBody
export interface CMSCreateUserRequestBody {
    email: XString;
    name: XString;
    roles: Array<Int>;
    surname?: XString | null;
    sendInvitation?: Bool | null;
}

// UXF\CMS\Http\Request\Cms\SaveUserConfigRequestBody
export interface CMSSaveUserConfigRequestBody {
    name: XString;
    data: Array<Mixed>;
}

// UXF\CMS\Http\Request\RecoveryPassword\PasswordRecoveryRequestBody
export interface CMSPasswordRecoveryRequestBody {
    password: XString;
    token: XString;
}

// UXF\CMS\Http\Request\RecoveryPassword\SetPasswordRequestBody
export interface CMSSetPasswordRequestBody {
    password: XString;
}

// UXF\CMS\Http\Request\RecoveryPassword\VerifyTokenRequestBody
export interface CMSVerifyTokenRequestBody {
    token: XString;
}

// UXF\CMS\Http\Response\StatusResponse
export interface CMSStatusResponse {
    success: Bool;
}

// UXF\CMS\Http\Response\UserInfoResponse
export interface CMSUserInfoResponse {
    user: CMSUserResponse;
    userConfig: Array<Mixed>;
}

// UXF\CMS\Http\Response\UserResponse
export interface CMSUserResponse {
    id: Int;
    email: XString;
    name: XString;
    roles: Array<XString>;
}

// UXF\Content\Entity\ContentLite
export interface ContentLite {
    data: Array<Mixed>;
    search: XString;
}

// UXF\Content\Entity\Seo
export interface ContentSeo {
    name: XString | null;
    title: XString;
    description: XString;
    ogTitle: XString;
    ogDescription: XString;
    ogImage: Int | null;
}

// UXF\Content\Http\AuthorResponse
export interface ContentAuthorResponse {
    id: Int;
    firstName: XString;
    surname: XString;
    avatar: StorageFileResponse | null;
}

// UXF\Content\Http\Cms\ContentCmsRequestBody
export interface ContentCmsRequestBody {
    type: XString;
    name: XString;
    perex: XString;
    seo: ContentSeo;
    visibilityLevel: VisibilityLevel;
    hidden: Bool;
    image: Int | null;
    publishedAt: DateTime | null;
    author: Int | null;
    content: ContentLite;
    tags: Array<Int>;
    category?: Int | null;
}

// UXF\Content\Http\Cms\ContentCmsResponse
export interface ContentCmsResponse {
    id: Int;
    type: XString;
    name: XString;
    perex: XString;
    seo: ContentSeoResponse;
    visibilityLevel: VisibilityLevel;
    hidden: Bool;
    image: StorageFileResponse | null;
    publishedAt: DateTime | null;
    author: ContentAuthorResponse | null;
    content: Array<Mixed>;
    tags: Array<ContentTagResponse>;
    createdAt: DateTime;
    updatedAt: DateTime;
}

// UXF\Content\Http\SeoResponse
export interface ContentSeoResponse {
    name: XString | null;
    title: XString;
    description: XString;
    ogTitle: XString;
    ogDescription: XString;
    ogImage: StorageFileResponse | null;
}

// UXF\Content\Http\TagResponse
export interface ContentTagResponse {
    id: Int;
    code: XString;
    label: XString;
}

// UXF\Core\Contract\Autocomplete\AutocompleteResult
export interface AutocompleteResult {
    id: UUID | Int | XString;
    label: XString;
}

type Date = string;

type DateTime = string;

// UXF\Core\Type\Money
export interface Money {
    amount: XString;
    currency: Currency;
}

type Phone = string;

type Time = string;

// UXF\DataGrid\Column\ColumnConfig
export interface DataGridColumnConfig {
    width?: Int | XString;
    isHidden?: Bool;
}

// UXF\DataGrid\DataGridSort
export interface DataGridSort {
    name: XString;
    dir: DataGridSortDir;
}

// UXF\DataGrid\Http\DataGridAutocompleteRequestQuery
export interface DataGridAutocompleteRequestQuery {
    term?: XString;
}

// UXF\DataGrid\Http\DataGridExportRequestQuery
export interface DataGridExportRequestQuery {
    format?: XString;
}

// UXF\DataGrid\Http\DataGridRequest
export interface DataGridRequest {
    f?: Array<DataGridFilterRequest>;
    tab?: XString | null;
    search?: XString | null;
    s?: DataGridSort | null;
    page?: Int | null;
    perPage?: Int | null;
    sort?: XString | null;
    dir?: DataGridSortDir | null;
}

// UXF\DataGrid\Http\DataGridResponse
export interface DataGridResponse {
    result: Array<Mixed>;
    count: Int;
    totalCount: Int;
}

// UXF\DataGrid\Http\FilterRequest
export interface DataGridFilterRequest {
    name: XString;
    value?: Mixed | null;
    op?: XString | null;
}

// UXF\DataGrid\Schema\ColumnSchema
export interface DataGridColumnSchema {
    name: XString;
    label: XString;
    sort: Bool;
    type: XString;
    config: DataGridColumnConfig;
}

// UXF\DataGrid\Schema\DataGridSchema
export interface DataGridSchema {
    name: XString;
    columns: Array<DataGridColumnSchema>;
    filters: Array<DataGridFilterSchema>;
    tabs: Array<DataGridTabSchema>;
    fullText: Bool;
    s: DataGridSort;
    perPage: Int;
    sort: XString;
    dir: DataGridSortDir;
}

// UXF\DataGrid\Schema\FilterOption
export interface DataGridFilterOption {
    label: XString;
    id: Mixed | null;
}

// UXF\DataGrid\Schema\FilterSchema
export interface DataGridFilterSchema {
    name: XString;
    label: XString;
    type: XString;
    autocomplete: XString | null;
    options: Array<DataGridFilterOption> | null;
}

// UXF\DataGrid\Schema\TabSchema
export interface DataGridTabSchema {
    name: XString;
    label: XString;
    icon: XString | null;
    s: DataGridSort | null;
}

// UXF\Form\Http\FormAutocompleteRequestQuery
export interface FormAutocompleteRequestQuery {
    term?: XString;
}

// UXF\Form\Schema\FieldSchema
export interface FormFieldSchema {
    name: XString;
    type: XString;
    label: XString;
    required: Bool;
    readOnly: Bool;
    editable: Bool;
    autocomplete: XString | null;
    options: Array<FormOptionSchema> | null;
    fields: Array<FormFieldSchema> | null;
}

// UXF\Form\Schema\FormSchema
export interface FormSchema {
    fields: Array<FormFieldSchema>;
}

// UXF\Form\Schema\OptionSchema
export interface FormOptionSchema {
    id: Int | XString;
    label: XString;
    color: XString | null;
}

// UXF\Storage\Http\Response\FileResponse
export interface StorageFileResponse {
    id: Int;
    uuid: UUID;
    type: XString;
    extension: XString;
    name: XString;
    namespace: XString;
    size: Int;
    createdAt: DateTime;
}

// UXF\Storage\Http\Response\ImageResponse
export interface StorageImageResponse {
    id: Int;
    uuid: UUID;
    type: XString;
    extension: XString;
    name: XString;
    namespace: XString;
    width: Int;
    height: Int;
    size: Int;
    createdAt: DateTime;
}

type Bool = boolean;

type Float = number;

type Int = number;

type Mixed = any;

type XString = string;

const formOptions = <T extends Record<string, { label: string; }>>(o: T) =>
    Object.entries(o).map(([id, { label }]) => ({
        id: id as keyof T,
        label,
    }));

const formIntOptions = <T extends Record<string, { label: string; }>>(o: T) =>
    Object.entries(o).map(([id, { label }]) => ({
        id: Number(id) as keyof T,
        label,
    }));

// App\AdminZone\Core\Enum\EntityAlias
export const EntityAliasOptions: Record<EntityAlias, {label: string, color: null}> = {
    "article-category": {
        "label": "ARTICLE_CATEGORY",
        "color": null
    }
};
export const enum_EntityAlias_array = formOptions(EntityAliasOptions);
export const enum_EntityAlias = (value: EntityAlias) => EntityAliasOptions[value];

// App\AdminZone\Core\Enum\EntityNames
export const EntityNamesOptions: Record<EntityNames, {label: string, color: any}> = {};
export const enum_EntityNames_array = formOptions(EntityNamesOptions);
export const enum_EntityNames = (value: EntityNames) => EntityNamesOptions[value];

// 
export const UserRoleOptions: Record<UserRole, {label: string, color: null}> = {
    "ROLE_ADMIN": {
        "label": "ROLE_ADMIN",
        "color": null
    },
    "ROLE_ROOT": {
        "label": "ROLE_ROOT",
        "color": null
    },
    "ROLE_USER": {
        "label": "ROLE_USER",
        "color": null
    }
};
export const enum_UserRole_array = formOptions(UserRoleOptions);
export const enum_UserRole = (value: UserRole) => UserRoleOptions[value];

// App\CoreZone\Enum\DataGridColumnType
export const DataGridColumnTypeOptions: Record<DataGridColumnType, {label: string, color: null}> = {
    "external-link": {
        "label": "EXTERNAL_LINK",
        "color": null
    },
    "meal-type": {
        "label": "MEAL_TYPE",
        "color": null
    },
    "meal-name": {
        "label": "MEAL_NAME",
        "color": null
    },
    "meal-unit": {
        "label": "MEAL_UNIT",
        "color": null
    },
    "allergens": {
        "label": "ALLERGENS",
        "color": null
    },
    "price": {
        "label": "PRICE",
        "color": null
    },
    "quantity": {
        "label": "QUANTITY",
        "color": null
    },
    "date": {
        "label": "DATE",
        "color": null
    },
    "order-item-state": {
        "label": "ORDER_ITEM_STATE",
        "color": null
    },
    "restaurant-credit-journal-state": {
        "label": "RESTAURANT_CREDIT_JOURNAL_STATE",
        "color": null
    },
    "restaurant-report-state": {
        "label": "RESTAURANT_REPORT_STATE",
        "color": null
    }
};
export const enum_DataGridColumnType_array = formOptions(DataGridColumnTypeOptions);
export const enum_DataGridColumnType = (value: DataGridColumnType) => DataGridColumnTypeOptions[value];

// App\OrderZone\Enum\OrderItemState
export const OrderItemStateOptions: Record<OrderItemState, {label: string, color: null}> = {
    "ORDERED": {
        "label": "Objednáno",
        "color": null
    },
    "PAYMENT_STARTED": {
        "label": "Platba zahájena",
        "color": null
    },
    "PAYMENT_FAILED": {
        "label": "Platba se nepovedla",
        "color": null
    },
    "PAID": {
        "label": "Zaplaceno",
        "color": null
    },
    "DELIVERED": {
        "label": "Vydáno",
        "color": null
    },
    "CANCELED_BY_USER_BEFORE_DEADLINE": {
        "label": "Stornováno uživatelem před deadline",
        "color": null
    },
    "CANCELED_BY_USER_AFTER_DEADLINE": {
        "label": "Stornováno uživatelem po deadline",
        "color": null
    },
    "CANCELED_BY_RESTAURANT_BEFORE_DEADLINE": {
        "label": "Stornováno restaurací před deadline",
        "color": null
    },
    "CANCELED_BY_RESTAURANT_AFTER_DEADLINE": {
        "label": "Stornováno restaurací po deadline",
        "color": null
    },
    "REFUNDED_EXPIRED_ORDER": {
        "label": "Refundováno, zaplacená objednávka vypršela",
        "color": null
    }
};
export const enum_OrderItemState_array = formOptions(OrderItemStateOptions);
export const enum_OrderItemState = (value: OrderItemState) => OrderItemStateOptions[value];

// App\OrderZone\Enum\PaymentState
export const PaymentStateOptions: Record<PaymentState, {label: string, color: null}> = {
    "STARTED": {
        "label": "Platba zahájena",
        "color": null
    },
    "PAID": {
        "label": "Zaplaceno",
        "color": null
    },
    "CANCELED": {
        "label": "Zrušeno",
        "color": null
    }
};
export const enum_PaymentState_array = formOptions(PaymentStateOptions);
export const enum_PaymentState = (value: PaymentState) => PaymentStateOptions[value];

// App\OrderZone\Enum\RestaurantCreditJournalType
export const RestaurantCreditJournalTypeOptions: Record<RestaurantCreditJournalType, {label: string, color: null}> = {
    "MEAL_PAYMENT": {
        "label": "Platba za jídlo",
        "color": null
    },
    "CANCELLATION_FEE": {
        "label": "Poplatek (pokuta) za stornovanou objednávku",
        "color": null
    },
    "CANCELLATION_BONUS": {
        "label": "Poplatek (bonus) za neodebranou objednávku",
        "color": null
    },
    "OTHER": {
        "label": "Ostatní",
        "color": null
    }
};
export const enum_RestaurantCreditJournalType_array = formOptions(RestaurantCreditJournalTypeOptions);
export const enum_RestaurantCreditJournalType = (value: RestaurantCreditJournalType) => RestaurantCreditJournalTypeOptions[value];

// App\OrderZone\Enum\VoucherState
export const VoucherStateOptions: Record<VoucherState, {label: string, color: null}> = {
    "INVALID_TOKEN": {
        "label": "Neplatný kód",
        "color": null
    },
    "VALID": {
        "label": "Platný",
        "color": null
    },
    "VALID_AFTER_DEADLINE": {
        "label": "Platný (po době vydání)",
        "color": null
    },
    "VALID_PARTIALLY_DELIVERED": {
        "label": "Platný (částečně vydáno)",
        "color": null
    },
    "VALID_FULLY_DELIVERED": {
        "label": "Platný, plně uplatněn",
        "color": null
    },
    "VALID_CANCELED": {
        "label": "Stornován",
        "color": null
    }
};
export const enum_VoucherState_array = formOptions(VoucherStateOptions);
export const enum_VoucherState = (value: VoucherState) => VoucherStateOptions[value];

// App\RestaurantZone\Enum\MealUnit
export const MealUnitOptions: Record<MealUnit, {label: string, color: null}> = {
    "GRAM": {
        "label": "g",
        "color": null
    },
    "PIECE": {
        "label": "ks",
        "color": null
    },
    "MILLILITER": {
        "label": "ml",
        "color": null
    },
    "LITER": {
        "label": "l",
        "color": null
    }
};
export const enum_MealUnit_array = formOptions(MealUnitOptions);
export const enum_MealUnit = (value: MealUnit) => MealUnitOptions[value];

// App\RestaurantZone\Enum\RestaurantProfileRole
export const RestaurantProfileRoleOptions: Record<RestaurantProfileRole, {label: string, color: "purple" | "yellow"}> = {
    "ADMIN": {
        "label": "Administrátor restaurace",
        "color": "purple"
    },
    "SERVICE": {
        "label": "Obsluha",
        "color": "yellow"
    }
};
export const enum_RestaurantProfileRole_array = formOptions(RestaurantProfileRoleOptions);
export const enum_RestaurantProfileRole = (value: RestaurantProfileRole) => RestaurantProfileRoleOptions[value];

// App\RestaurantZone\Enum\RestaurantReportState
export const RestaurantReportStateOptions: Record<RestaurantReportState, {label: string, color: null}> = {
    "CURRENT": {
        "label": "Současný přehled",
        "color": null
    },
    "FOR_PAYMENT": {
        "label": "K proplacení",
        "color": null
    },
    "PAID": {
        "label": "Proplaceno",
        "color": null
    }
};
export const enum_RestaurantReportState_array = formOptions(RestaurantReportStateOptions);
export const enum_RestaurantReportState = (value: RestaurantReportState) => RestaurantReportStateOptions[value];

// UXF\Content\Entity\VisibilityLevel
export const VisibilityLevelOptions: Record<VisibilityLevel, {label: string, color: null}> = {
    "PUBLIC": {
        "label": "PUBLIC",
        "color": null
    },
    "PUBLIC_WITHOUT_SITEMAP": {
        "label": "PUBLIC_WITHOUT_SITEMAP",
        "color": null
    },
    "PRIVATE": {
        "label": "PRIVATE",
        "color": null
    }
};
export const enum_VisibilityLevel_array = formOptions(VisibilityLevelOptions);
export const enum_VisibilityLevel = (value: VisibilityLevel) => VisibilityLevelOptions[value];

// 
export const CurrencyOptions: Record<Currency, {label: string, color: null}> = {
    "AED": {
        "label": "AED",
        "color": null
    },
    "AFN": {
        "label": "AFN",
        "color": null
    },
    "ALL": {
        "label": "ALL",
        "color": null
    },
    "AMD": {
        "label": "AMD",
        "color": null
    },
    "ANG": {
        "label": "ANG",
        "color": null
    },
    "AOA": {
        "label": "AOA",
        "color": null
    },
    "ARS": {
        "label": "ARS",
        "color": null
    },
    "AUD": {
        "label": "AUD",
        "color": null
    },
    "AWG": {
        "label": "AWG",
        "color": null
    },
    "AZN": {
        "label": "AZN",
        "color": null
    },
    "BAM": {
        "label": "BAM",
        "color": null
    },
    "BBD": {
        "label": "BBD",
        "color": null
    },
    "BDT": {
        "label": "BDT",
        "color": null
    },
    "BGN": {
        "label": "BGN",
        "color": null
    },
    "BHD": {
        "label": "BHD",
        "color": null
    },
    "BIF": {
        "label": "BIF",
        "color": null
    },
    "BMD": {
        "label": "BMD",
        "color": null
    },
    "BND": {
        "label": "BND",
        "color": null
    },
    "BOB": {
        "label": "BOB",
        "color": null
    },
    "BOV": {
        "label": "BOV",
        "color": null
    },
    "BRL": {
        "label": "BRL",
        "color": null
    },
    "BSD": {
        "label": "BSD",
        "color": null
    },
    "BTN": {
        "label": "BTN",
        "color": null
    },
    "BWP": {
        "label": "BWP",
        "color": null
    },
    "BYN": {
        "label": "BYN",
        "color": null
    },
    "BZD": {
        "label": "BZD",
        "color": null
    },
    "CAD": {
        "label": "CAD",
        "color": null
    },
    "CDF": {
        "label": "CDF",
        "color": null
    },
    "CHE": {
        "label": "CHE",
        "color": null
    },
    "CHF": {
        "label": "CHF",
        "color": null
    },
    "CHW": {
        "label": "CHW",
        "color": null
    },
    "CLF": {
        "label": "CLF",
        "color": null
    },
    "CLP": {
        "label": "CLP",
        "color": null
    },
    "CNY": {
        "label": "CNY",
        "color": null
    },
    "COP": {
        "label": "COP",
        "color": null
    },
    "COU": {
        "label": "COU",
        "color": null
    },
    "CRC": {
        "label": "CRC",
        "color": null
    },
    "CUC": {
        "label": "CUC",
        "color": null
    },
    "CUP": {
        "label": "CUP",
        "color": null
    },
    "CVE": {
        "label": "CVE",
        "color": null
    },
    "CZK": {
        "label": "CZK",
        "color": null
    },
    "DJF": {
        "label": "DJF",
        "color": null
    },
    "DKK": {
        "label": "DKK",
        "color": null
    },
    "DOP": {
        "label": "DOP",
        "color": null
    },
    "DZD": {
        "label": "DZD",
        "color": null
    },
    "EGP": {
        "label": "EGP",
        "color": null
    },
    "ERN": {
        "label": "ERN",
        "color": null
    },
    "ETB": {
        "label": "ETB",
        "color": null
    },
    "EUR": {
        "label": "EUR",
        "color": null
    },
    "FJD": {
        "label": "FJD",
        "color": null
    },
    "FKP": {
        "label": "FKP",
        "color": null
    },
    "GBP": {
        "label": "GBP",
        "color": null
    },
    "GEL": {
        "label": "GEL",
        "color": null
    },
    "GHS": {
        "label": "GHS",
        "color": null
    },
    "GIP": {
        "label": "GIP",
        "color": null
    },
    "GMD": {
        "label": "GMD",
        "color": null
    },
    "GNF": {
        "label": "GNF",
        "color": null
    },
    "GTQ": {
        "label": "GTQ",
        "color": null
    },
    "GYD": {
        "label": "GYD",
        "color": null
    },
    "HKD": {
        "label": "HKD",
        "color": null
    },
    "HNL": {
        "label": "HNL",
        "color": null
    },
    "HTG": {
        "label": "HTG",
        "color": null
    },
    "HUF": {
        "label": "HUF",
        "color": null
    },
    "IDR": {
        "label": "IDR",
        "color": null
    },
    "ILS": {
        "label": "ILS",
        "color": null
    },
    "INR": {
        "label": "INR",
        "color": null
    },
    "IQD": {
        "label": "IQD",
        "color": null
    },
    "IRR": {
        "label": "IRR",
        "color": null
    },
    "ISK": {
        "label": "ISK",
        "color": null
    },
    "JMD": {
        "label": "JMD",
        "color": null
    },
    "JOD": {
        "label": "JOD",
        "color": null
    },
    "JPY": {
        "label": "JPY",
        "color": null
    },
    "KES": {
        "label": "KES",
        "color": null
    },
    "KGS": {
        "label": "KGS",
        "color": null
    },
    "KHR": {
        "label": "KHR",
        "color": null
    },
    "KMF": {
        "label": "KMF",
        "color": null
    },
    "KPW": {
        "label": "KPW",
        "color": null
    },
    "KRW": {
        "label": "KRW",
        "color": null
    },
    "KWD": {
        "label": "KWD",
        "color": null
    },
    "KYD": {
        "label": "KYD",
        "color": null
    },
    "KZT": {
        "label": "KZT",
        "color": null
    },
    "LAK": {
        "label": "LAK",
        "color": null
    },
    "LBP": {
        "label": "LBP",
        "color": null
    },
    "LKR": {
        "label": "LKR",
        "color": null
    },
    "LRD": {
        "label": "LRD",
        "color": null
    },
    "LSL": {
        "label": "LSL",
        "color": null
    },
    "LYD": {
        "label": "LYD",
        "color": null
    },
    "MAD": {
        "label": "MAD",
        "color": null
    },
    "MDL": {
        "label": "MDL",
        "color": null
    },
    "MGA": {
        "label": "MGA",
        "color": null
    },
    "MKD": {
        "label": "MKD",
        "color": null
    },
    "MMK": {
        "label": "MMK",
        "color": null
    },
    "MNT": {
        "label": "MNT",
        "color": null
    },
    "MOP": {
        "label": "MOP",
        "color": null
    },
    "MRU": {
        "label": "MRU",
        "color": null
    },
    "MUR": {
        "label": "MUR",
        "color": null
    },
    "MVR": {
        "label": "MVR",
        "color": null
    },
    "MWK": {
        "label": "MWK",
        "color": null
    },
    "MXN": {
        "label": "MXN",
        "color": null
    },
    "MXV": {
        "label": "MXV",
        "color": null
    },
    "MYR": {
        "label": "MYR",
        "color": null
    },
    "MZN": {
        "label": "MZN",
        "color": null
    },
    "NAD": {
        "label": "NAD",
        "color": null
    },
    "NGN": {
        "label": "NGN",
        "color": null
    },
    "NIO": {
        "label": "NIO",
        "color": null
    },
    "NOK": {
        "label": "NOK",
        "color": null
    },
    "NPR": {
        "label": "NPR",
        "color": null
    },
    "NZD": {
        "label": "NZD",
        "color": null
    },
    "OMR": {
        "label": "OMR",
        "color": null
    },
    "PAB": {
        "label": "PAB",
        "color": null
    },
    "PEN": {
        "label": "PEN",
        "color": null
    },
    "PGK": {
        "label": "PGK",
        "color": null
    },
    "PHP": {
        "label": "PHP",
        "color": null
    },
    "PKR": {
        "label": "PKR",
        "color": null
    },
    "PLN": {
        "label": "PLN",
        "color": null
    },
    "PYG": {
        "label": "PYG",
        "color": null
    },
    "QAR": {
        "label": "QAR",
        "color": null
    },
    "RON": {
        "label": "RON",
        "color": null
    },
    "RSD": {
        "label": "RSD",
        "color": null
    },
    "RUB": {
        "label": "RUB",
        "color": null
    },
    "RWF": {
        "label": "RWF",
        "color": null
    },
    "SAR": {
        "label": "SAR",
        "color": null
    },
    "SBD": {
        "label": "SBD",
        "color": null
    },
    "SCR": {
        "label": "SCR",
        "color": null
    },
    "SDG": {
        "label": "SDG",
        "color": null
    },
    "SEK": {
        "label": "SEK",
        "color": null
    },
    "SGD": {
        "label": "SGD",
        "color": null
    },
    "SHP": {
        "label": "SHP",
        "color": null
    },
    "SLE": {
        "label": "SLE",
        "color": null
    },
    "SLL": {
        "label": "SLL",
        "color": null
    },
    "SOS": {
        "label": "SOS",
        "color": null
    },
    "SRD": {
        "label": "SRD",
        "color": null
    },
    "SSP": {
        "label": "SSP",
        "color": null
    },
    "STN": {
        "label": "STN",
        "color": null
    },
    "SVC": {
        "label": "SVC",
        "color": null
    },
    "SYP": {
        "label": "SYP",
        "color": null
    },
    "SZL": {
        "label": "SZL",
        "color": null
    },
    "THB": {
        "label": "THB",
        "color": null
    },
    "TJS": {
        "label": "TJS",
        "color": null
    },
    "TMT": {
        "label": "TMT",
        "color": null
    },
    "TND": {
        "label": "TND",
        "color": null
    },
    "TOP": {
        "label": "TOP",
        "color": null
    },
    "TRY": {
        "label": "TRY",
        "color": null
    },
    "TTD": {
        "label": "TTD",
        "color": null
    },
    "TWD": {
        "label": "TWD",
        "color": null
    },
    "TZS": {
        "label": "TZS",
        "color": null
    },
    "UAH": {
        "label": "UAH",
        "color": null
    },
    "UGX": {
        "label": "UGX",
        "color": null
    },
    "USD": {
        "label": "USD",
        "color": null
    },
    "USN": {
        "label": "USN",
        "color": null
    },
    "UYI": {
        "label": "UYI",
        "color": null
    },
    "UYU": {
        "label": "UYU",
        "color": null
    },
    "UYW": {
        "label": "UYW",
        "color": null
    },
    "UZS": {
        "label": "UZS",
        "color": null
    },
    "VED": {
        "label": "VED",
        "color": null
    },
    "VES": {
        "label": "VES",
        "color": null
    },
    "VND": {
        "label": "VND",
        "color": null
    },
    "VUV": {
        "label": "VUV",
        "color": null
    },
    "WST": {
        "label": "WST",
        "color": null
    },
    "XAF": {
        "label": "XAF",
        "color": null
    },
    "XCD": {
        "label": "XCD",
        "color": null
    },
    "XOF": {
        "label": "XOF",
        "color": null
    },
    "XPF": {
        "label": "XPF",
        "color": null
    },
    "YER": {
        "label": "YER",
        "color": null
    },
    "ZAR": {
        "label": "ZAR",
        "color": null
    },
    "ZMW": {
        "label": "ZMW",
        "color": null
    },
    "ZWL": {
        "label": "ZWL",
        "color": null
    }
};
export const enum_Currency_array = formOptions(CurrencyOptions);
export const enum_Currency = (value: Currency) => CurrencyOptions[value];

// UXF\DataGrid\DataGridSortDir
export const DataGridSortDirOptions: Record<DataGridSortDir, {label: string, color: null}> = {
    "asc": {
        "label": "ASC",
        "color": null
    },
    "desc": {
        "label": "DESC",
        "color": null
    }
};
export const enum_DataGridSortDir_array = formOptions(DataGridSortDirOptions);
export const enum_DataGridSortDir = (value: DataGridSortDir) => DataGridSortDirOptions[value];

export const useApiRestaurantZoneGetAllergensGet = (config: RequestConfig<null, null, null>) => useAxiosRequest<Array<RestaurantAllergenResponse>, null, null, null>(`/api/restaurant/allergens`, 'get', config);

export const apiRestaurantZoneGetAllergensGet = (ctx: any) => axiosRequest<Array<RestaurantAllergenResponse>>(ctx, `/api/restaurant/allergens`, 'get', null, null);

export const useApiRestaurantZoneAddRestaurantDailyMenuPost = (config: RequestConfig<RestaurantDailyMenuRequestBody, null, { restaurant: Int }>) => useAxiosRequest<CMSStatusResponse, RestaurantDailyMenuRequestBody, null, { restaurant: Int }>(`/api/restaurant/${config.path.restaurant}/daily-menu`, 'post', config);

export const apiRestaurantZoneAddRestaurantDailyMenuPost = (ctx: any, path: { restaurant: Int }, body: RestaurantDailyMenuRequestBody) => axiosRequest<CMSStatusResponse>(ctx, `/api/restaurant/${path.restaurant}/daily-menu`, 'post', body, null);

export const useApiRestaurantZoneGetRestaurantDailyMenuGet = (config: RequestConfig<null, null, { restaurant: Int, date: Date }>) => useAxiosRequest<RestaurantDailyMenuResponse, null, null, { restaurant: Int, date: Date }>(`/api/restaurant/${config.path.restaurant}/daily-menu/${config.path.date}`, 'get', config);

export const apiRestaurantZoneGetRestaurantDailyMenuGet = (ctx: any, path: { restaurant: Int, date: Date }) => axiosRequest<RestaurantDailyMenuResponse>(ctx, `/api/restaurant/${path.restaurant}/daily-menu/${path.date}`, 'get', null, null);

export const useApiRestaurantZonePublishRestaurantDailyMenuPost = (config: RequestConfig<null, null, { restaurant: Int, date: Date }>) => useAxiosRequest<CMSStatusResponse, null, null, { restaurant: Int, date: Date }>(`/api/restaurant/${config.path.restaurant}/daily-menu/${config.path.date}/publish`, 'post', config);

export const apiRestaurantZonePublishRestaurantDailyMenuPost = (ctx: any, path: { restaurant: Int, date: Date }) => axiosRequest<CMSStatusResponse>(ctx, `/api/restaurant/${path.restaurant}/daily-menu/${path.date}/publish`, 'post', null, null);

export const useApiRestaurantZonePutDailyMenuItemLimitOrderCountPut = (config: RequestConfig<RestaurantCountRequestBody, null, { restaurant: Int, dailyMenuItem: Int }>) => useAxiosRequest<CMSStatusResponse, RestaurantCountRequestBody, null, { restaurant: Int, dailyMenuItem: Int }>(`/api/restaurant/${config.path.restaurant}/daily-menu-item/${config.path.dailyMenuItem}/limit-order-count`, 'put', config);

export const apiRestaurantZonePutDailyMenuItemLimitOrderCountPut = (ctx: any, path: { restaurant: Int, dailyMenuItem: Int }, body: RestaurantCountRequestBody) => axiosRequest<CMSStatusResponse>(ctx, `/api/restaurant/${path.restaurant}/daily-menu-item/${path.dailyMenuItem}/limit-order-count`, 'put', body, null);

export const useApiRestaurantZonePutDailyMenuItemPriceApplicationPut = (config: RequestConfig<RestaurantPriceRequestBody, null, { restaurant: Int, dailyMenuItem: Int }>) => useAxiosRequest<CMSStatusResponse, RestaurantPriceRequestBody, null, { restaurant: Int, dailyMenuItem: Int }>(`/api/restaurant/${config.path.restaurant}/daily-menu-item/${config.path.dailyMenuItem}/price-application`, 'put', config);

export const apiRestaurantZonePutDailyMenuItemPriceApplicationPut = (ctx: any, path: { restaurant: Int, dailyMenuItem: Int }, body: RestaurantPriceRequestBody) => axiosRequest<CMSStatusResponse>(ctx, `/api/restaurant/${path.restaurant}/daily-menu-item/${path.dailyMenuItem}/price-application`, 'put', body, null);

export const useApiRestaurantZonePutDailyMenuItemPriceRestaurantPut = (config: RequestConfig<RestaurantPriceRequestBody, null, { restaurant: Int, dailyMenuItem: Int }>) => useAxiosRequest<CMSStatusResponse, RestaurantPriceRequestBody, null, { restaurant: Int, dailyMenuItem: Int }>(`/api/restaurant/${config.path.restaurant}/daily-menu-item/${config.path.dailyMenuItem}/price-restaurant`, 'put', config);

export const apiRestaurantZonePutDailyMenuItemPriceRestaurantPut = (ctx: any, path: { restaurant: Int, dailyMenuItem: Int }, body: RestaurantPriceRequestBody) => axiosRequest<CMSStatusResponse>(ctx, `/api/restaurant/${path.restaurant}/daily-menu-item/${path.dailyMenuItem}/price-restaurant`, 'put', body, null);

export const useApiRestaurantZonePostDailyMenuItemSetNotActivePost = (config: RequestConfig<null, null, { restaurant: Int, dailyMenuItem: Int }>) => useAxiosRequest<CMSStatusResponse, null, null, { restaurant: Int, dailyMenuItem: Int }>(`/api/restaurant/${config.path.restaurant}/daily-menu-item/${config.path.dailyMenuItem}/set-not-active`, 'post', config);

export const apiRestaurantZonePostDailyMenuItemSetNotActivePost = (ctx: any, path: { restaurant: Int, dailyMenuItem: Int }) => axiosRequest<CMSStatusResponse>(ctx, `/api/restaurant/${path.restaurant}/daily-menu-item/${path.dailyMenuItem}/set-not-active`, 'post', null, null);

export const useApiRestaurantZoneUnpublishRestaurantDailyMenuPost = (config: RequestConfig<null, null, { restaurant: Int, date: Date }>) => useAxiosRequest<CMSStatusResponse, null, null, { restaurant: Int, date: Date }>(`/api/restaurant/${config.path.restaurant}/daily-menu/${config.path.date}/unpublish`, 'post', config);

export const apiRestaurantZoneUnpublishRestaurantDailyMenuPost = (ctx: any, path: { restaurant: Int, date: Date }) => axiosRequest<CMSStatusResponse>(ctx, `/api/restaurant/${path.restaurant}/daily-menu/${path.date}/unpublish`, 'post', null, null);

export const useApiRestaurantZoneGetMealGet = (config: RequestConfig<null, null, { meal: Int, restaurant: Int }>) => useAxiosRequest<RestaurantMealResponse, null, null, { meal: Int, restaurant: Int }>(`/api/restaurant/${config.path.restaurant}/meal/${config.path.meal}`, 'get', config);

export const apiRestaurantZoneGetMealGet = (ctx: any, path: { meal: Int, restaurant: Int }) => axiosRequest<RestaurantMealResponse>(ctx, `/api/restaurant/${path.restaurant}/meal/${path.meal}`, 'get', null, null);

export const useApiRestaurantZoneGetMealsGet = (config: RequestConfig<null, RestaurantSearchMealsRequestQuery, { restaurant: Int }>) => useAxiosRequest<Array<RestaurantMealResponse>, null, RestaurantSearchMealsRequestQuery, { restaurant: Int }>(`/api/restaurant/${config.path.restaurant}/meal`, 'get', config);

export const apiRestaurantZoneGetMealsGet = (ctx: any, path: { restaurant: Int }, query: RestaurantSearchMealsRequestQuery) => axiosRequest<Array<RestaurantMealResponse>>(ctx, `/api/restaurant/${path.restaurant}/meal`, 'get', null, query);

export const useApiRestaurantZoneGetMealTypesGet = (config: RequestConfig<null, null, null>) => useAxiosRequest<Array<RestaurantMealTypeResponse>, null, null, null>(`/api/restaurant/meal-types`, 'get', config);

export const apiRestaurantZoneGetMealTypesGet = (ctx: any) => axiosRequest<Array<RestaurantMealTypeResponse>>(ctx, `/api/restaurant/meal-types`, 'get', null, null);

export const useApiRestaurantZoneGetRestaurantPrintReportGet = (config: RequestConfig<null, null, { restaurant: Int, dateFrom: Date }>) => useAxiosRequest<RestaurantPrintReportResponse, null, null, { restaurant: Int, dateFrom: Date }>(`/api/restaurant/${config.path.restaurant}/print-report/${config.path.dateFrom}`, 'get', config);

export const apiRestaurantZoneGetRestaurantPrintReportGet = (ctx: any, path: { restaurant: Int, dateFrom: Date }) => axiosRequest<RestaurantPrintReportResponse>(ctx, `/api/restaurant/${path.restaurant}/print-report/${path.dateFrom}`, 'get', null, null);

export const useApiRestaurantZoneGetRestaurantReportGet = (config: RequestConfig<null, null, { restaurant: Int }>) => useAxiosRequest<RestaurantReportResponse, null, null, { restaurant: Int }>(`/api/restaurant/${config.path.restaurant}/report`, 'get', config);

export const apiRestaurantZoneGetRestaurantReportGet = (ctx: any, path: { restaurant: Int }) => axiosRequest<RestaurantReportResponse>(ctx, `/api/restaurant/${path.restaurant}/report`, 'get', null, null);

export const useApiRestaurantZoneGetRestaurantReportSummaryGet = (config: RequestConfig<null, null, { restaurant: Int, dateFrom: Date }>) => useAxiosRequest<RestaurantReportSummaryResponse, null, null, { restaurant: Int, dateFrom: Date }>(`/api/restaurant/${config.path.restaurant}/report-summary/${config.path.dateFrom}`, 'get', config);

export const apiRestaurantZoneGetRestaurantReportSummaryGet = (ctx: any, path: { restaurant: Int, dateFrom: Date }) => axiosRequest<RestaurantReportSummaryResponse>(ctx, `/api/restaurant/${path.restaurant}/report-summary/${path.dateFrom}`, 'get', null, null);

export const useApiRestaurantZoneGetRestaurantReportItemsGet = (config: RequestConfig<null, null, { restaurant: Int, dateFrom: Date }>) => useAxiosRequest<RestaurantReportItemsResponse, null, null, { restaurant: Int, dateFrom: Date }>(`/api/restaurant/${config.path.restaurant}/report-items/${config.path.dateFrom}`, 'get', config);

export const apiRestaurantZoneGetRestaurantReportItemsGet = (ctx: any, path: { restaurant: Int, dateFrom: Date }) => axiosRequest<RestaurantReportItemsResponse>(ctx, `/api/restaurant/${path.restaurant}/report-items/${path.dateFrom}`, 'get', null, null);

export const useApiRestaurantZoneGetMyRestaurantsGet = (config: RequestConfig<null, null, null>) => useAxiosRequest<Array<RestaurantMyRestaurantResponse>, null, null, null>(`/api/restaurant/my-restaurants`, 'get', config);

export const apiRestaurantZoneGetMyRestaurantsGet = (ctx: any) => axiosRequest<Array<RestaurantMyRestaurantResponse>>(ctx, `/api/restaurant/my-restaurants`, 'get', null, null);

export const useApiRestaurantDatagridGetExportGet = (config: RequestConfig<null, DataGridRequest, { name: XString, restaurant: Int }>) => useAxiosRequest<Mixed, null, DataGridRequest, { name: XString, restaurant: Int }>(`/api/restaurant/${config.path.restaurant}/datagrid/export/${config.path.name}`, 'get', config);

export const apiRestaurantDatagridGetExportGet = (ctx: any, path: { name: XString, restaurant: Int }, query: DataGridRequest) => axiosRequest<Mixed>(ctx, `/api/restaurant/${path.restaurant}/datagrid/export/${path.name}`, 'get', null, query);

export const useApiRestaurantDatagridGetAutocompleteGet = (config: RequestConfig<null, DataGridAutocompleteRequestQuery, { name: XString, restaurant: Int }>) => useAxiosRequest<Array<AutocompleteResult>, null, DataGridAutocompleteRequestQuery, { name: XString, restaurant: Int }>(`/api/restaurant/${config.path.restaurant}/datagrid/autocomplete/${config.path.name}`, 'get', config);

export const apiRestaurantDatagridGetAutocompleteGet = (ctx: any, path: { name: XString, restaurant: Int }, query: DataGridAutocompleteRequestQuery) => axiosRequest<Array<AutocompleteResult>>(ctx, `/api/restaurant/${path.restaurant}/datagrid/autocomplete/${path.name}`, 'get', null, query);

export const useApiRestaurantZoneDeleteRestaurantProfileDelete = (config: RequestConfig<null, null, { restaurant: Int, restaurantProfile: Int }>) => useAxiosRequest<CMSStatusResponse, null, null, { restaurant: Int, restaurantProfile: Int }>(`/api/restaurant/${config.path.restaurant}/profile/${config.path.restaurantProfile}`, 'delete', config);

export const apiRestaurantZoneDeleteRestaurantProfileDelete = (ctx: any, path: { restaurant: Int, restaurantProfile: Int }) => axiosRequest<CMSStatusResponse>(ctx, `/api/restaurant/${path.restaurant}/profile/${path.restaurantProfile}`, 'delete', null, null);

export const useApiRestaurantZoneGetRestaurantProfileGet = (config: RequestConfig<null, null, { restaurant: Int, restaurantProfile: Int }>) => useAxiosRequest<RestaurantProfileResponse, null, null, { restaurant: Int, restaurantProfile: Int }>(`/api/restaurant/${config.path.restaurant}/profile/${config.path.restaurantProfile}`, 'get', config);

export const apiRestaurantZoneGetRestaurantProfileGet = (ctx: any, path: { restaurant: Int, restaurantProfile: Int }) => axiosRequest<RestaurantProfileResponse>(ctx, `/api/restaurant/${path.restaurant}/profile/${path.restaurantProfile}`, 'get', null, null);

export const useApiRestaurantZoneGetRestaurantProfilesGet = (config: RequestConfig<null, null, { restaurant: Int }>) => useAxiosRequest<Array<RestaurantProfileResponse>, null, null, { restaurant: Int }>(`/api/restaurant/${config.path.restaurant}/profiles`, 'get', config);

export const apiRestaurantZoneGetRestaurantProfilesGet = (ctx: any, path: { restaurant: Int }) => axiosRequest<Array<RestaurantProfileResponse>>(ctx, `/api/restaurant/${path.restaurant}/profiles`, 'get', null, null);

export const useApiRestaurantZoneGetRestaurantTagsGet = (config: RequestConfig<null, null, null>) => useAxiosRequest<Array<RestaurantTagResponse>, null, null, null>(`/api/restaurant/restaurant-tags`, 'get', config);

export const apiRestaurantZoneGetRestaurantTagsGet = (ctx: any) => axiosRequest<Array<RestaurantTagResponse>>(ctx, `/api/restaurant/restaurant-tags`, 'get', null, null);

export const useApiRestaurantDatagridGetDataGet = (config: RequestConfig<null, DataGridRequest, { name: XString, restaurant: Int }>) => useAxiosRequest<DataGridResponse, null, DataGridRequest, { name: XString, restaurant: Int }>(`/api/restaurant/${config.path.restaurant}/datagrid/${config.path.name}`, 'get', config);

export const apiRestaurantDatagridGetDataGet = (ctx: any, path: { name: XString, restaurant: Int }, query: DataGridRequest) => axiosRequest<DataGridResponse>(ctx, `/api/restaurant/${path.restaurant}/datagrid/${path.name}`, 'get', null, query);

export const useApiRestaurantZoneGetRestaurantGet = (config: RequestConfig<null, null, { restaurant: Int }>) => useAxiosRequest<RestaurantResponse, null, null, { restaurant: Int }>(`/api/restaurant/${config.path.restaurant}`, 'get', config);

export const apiRestaurantZoneGetRestaurantGet = (ctx: any, path: { restaurant: Int }) => axiosRequest<RestaurantResponse>(ctx, `/api/restaurant/${path.restaurant}`, 'get', null, null);

export const useCmsAutocompleteGet = (config: RequestConfig<null, CMSCmsAutocompleteRequestQuery, { name: XString }>) => useAxiosRequest<Array<AutocompleteResult>, null, CMSCmsAutocompleteRequestQuery, { name: XString }>(`/api/cms/autocomplete/${config.path.name}`, 'get', config);

export const cmsAutocompleteGet = (ctx: any, path: { name: XString }, query: CMSCmsAutocompleteRequestQuery) => axiosRequest<Array<AutocompleteResult>>(ctx, `/api/cms/autocomplete/${path.name}`, 'get', null, query);

export const useCmsChangePasswordPost = (config: RequestConfig<CMSChangePasswordRequestBody, null, null>) => useAxiosRequest<CMSStatusResponse, CMSChangePasswordRequestBody, null, null>(`/api/cms/change-password`, 'post', config);

export const cmsChangePasswordPost = (ctx: any, body: CMSChangePasswordRequestBody) => axiosRequest<CMSStatusResponse>(ctx, `/api/cms/change-password`, 'post', body, null);

export const useCmsChangeUserActivePost = (config: RequestConfig<null, null, { userId: Int }>) => useAxiosRequest<Mixed, null, null, { userId: Int }>(`/api/cms/change-user-active/${config.path.userId}`, 'post', config);

export const cmsChangeUserActivePost = (ctx: any, path: { userId: Int }) => axiosRequest<Mixed>(ctx, `/api/cms/change-user-active/${path.userId}`, 'post', null, null);

export const useCmsFileUploadPost = (config: RequestConfig<null, null, null>) => useAxiosRequest<Array<StorageFileResponse>, null, null, null>(`/api/cms/files/upload`, 'post', config);

export const cmsFileUploadPost = (ctx: any) => axiosRequest<Array<StorageFileResponse>>(ctx, `/api/cms/files/upload`, 'post', null, null);

export const useCmsInviteUserPost = (config: RequestConfig<CMSCreateUserRequestBody, null, null>) => useAxiosRequest<Mixed, CMSCreateUserRequestBody, null, null>(`/api/cms/invite-user`, 'post', config);

export const cmsInviteUserPost = (ctx: any, body: CMSCreateUserRequestBody) => axiosRequest<Mixed>(ctx, `/api/cms/invite-user`, 'post', body, null);

export const useCmsMeGet = (config: RequestConfig<null, null, null>) => useAxiosRequest<CMSUserResponse, null, null, null>(`/api/cms/me`, 'get', config);

export const cmsMeGet = (ctx: any) => axiosRequest<CMSUserResponse>(ctx, `/api/cms/me`, 'get', null, null);

export const useCmsSetPasswordPost = (config: RequestConfig<CMSSetPasswordRequestBody, null, { user: Int }>) => useAxiosRequest<CMSStatusResponse, CMSSetPasswordRequestBody, null, { user: Int }>(`/api/cms/set-password/${config.path.user}`, 'post', config);

export const cmsSetPasswordPost = (ctx: any, path: { user: Int }, body: CMSSetPasswordRequestBody) => axiosRequest<CMSStatusResponse>(ctx, `/api/cms/set-password/${path.user}`, 'post', body, null);

export const useCmsTablesGet = (config: RequestConfig<null, null, null>) => useAxiosRequest<Array<Mixed>, null, null, null>(`/api/cms/tables`, 'get', config);

export const cmsTablesGet = (ctx: any) => axiosRequest<Array<Mixed>>(ctx, `/api/cms/tables`, 'get', null, null);

export const useCmsUserConfigSavePost = (config: RequestConfig<CMSSaveUserConfigRequestBody, null, null>) => useAxiosRequest<Record<string, Mixed>, CMSSaveUserConfigRequestBody, null, null>(`/api/cms/user-config`, 'post', config);

export const cmsUserConfigSavePost = (ctx: any, body: CMSSaveUserConfigRequestBody) => axiosRequest<Record<string, Mixed>>(ctx, `/api/cms/user-config`, 'post', body, null);

export const useCmsUserConfigGetGet = (config: RequestConfig<null, null, null>) => useAxiosRequest<Record<string, Mixed>, null, null, null>(`/api/cms/user-config`, 'get', config);

export const cmsUserConfigGetGet = (ctx: any) => axiosRequest<Record<string, Mixed>>(ctx, `/api/cms/user-config`, 'get', null, null);

export const useCmsUserGet = (config: RequestConfig<null, null, null>) => useAxiosRequest<CMSUserInfoResponse, null, null, null>(`/api/cms/user`, 'get', config);

export const cmsUserGet = (ctx: any) => axiosRequest<CMSUserInfoResponse>(ctx, `/api/cms/user`, 'get', null, null);

export const useCmsUserConfigGet = (config: RequestConfig<null, null, { name: XString }>) => useAxiosRequest<Mixed, null, null, { name: XString }>(`/api/cms/user-config/${config.path.name}`, 'get', config);

export const cmsUserConfigGet = (ctx: any, path: { name: XString }) => axiosRequest<Mixed>(ctx, `/api/cms/user-config/${path.name}`, 'get', null, null);

export const useCmsUserConfigUpdatePut = (config: RequestConfig<Mixed, null, { name: XString }>) => useAxiosRequest<Mixed, Mixed, null, { name: XString }>(`/api/cms/user-config/${config.path.name}`, 'put', config);

export const cmsUserConfigUpdatePut = (ctx: any, path: { name: XString }, body: Mixed) => axiosRequest<Mixed>(ctx, `/api/cms/user-config/${path.name}`, 'put', body, null);

export const useApiPasswordRecoveryPost = (config: RequestConfig<CMSPasswordRecoveryRequestBody, null, null>) => useAxiosRequest<Mixed, CMSPasswordRecoveryRequestBody, null, null>(`/api/password-recovery`, 'post', config);

export const apiPasswordRecoveryPost = (ctx: any, body: CMSPasswordRecoveryRequestBody) => axiosRequest<Mixed>(ctx, `/api/password-recovery`, 'post', body, null);

export const useApiPasswordRecoveryVerifyTokenPost = (config: RequestConfig<CMSVerifyTokenRequestBody, null, null>) => useAxiosRequest<Mixed, CMSVerifyTokenRequestBody, null, null>(`/api/password-recovery-verify-token`, 'post', config);

export const apiPasswordRecoveryVerifyTokenPost = (ctx: any, body: CMSVerifyTokenRequestBody) => axiosRequest<Mixed>(ctx, `/api/password-recovery-verify-token`, 'post', body, null);

export const useContentCreatePost = (config: RequestConfig<ContentCmsRequestBody, null, null>) => useAxiosRequest<ContentCmsResponse, ContentCmsRequestBody, null, null>(`/api/cms/content`, 'post', config);

export const contentCreatePost = (ctx: any, body: ContentCmsRequestBody) => axiosRequest<ContentCmsResponse>(ctx, `/api/cms/content`, 'post', body, null);

export const useContentUpdatePut = (config: RequestConfig<ContentCmsRequestBody, null, { content: Int }>) => useAxiosRequest<ContentCmsResponse, ContentCmsRequestBody, null, { content: Int }>(`/api/cms/content/${config.path.content}`, 'put', config);

export const contentUpdatePut = (ctx: any, path: { content: Int }, body: ContentCmsRequestBody) => axiosRequest<ContentCmsResponse>(ctx, `/api/cms/content/${path.content}`, 'put', body, null);

export const useContentDeleteDelete = (config: RequestConfig<null, null, { content: Int }>) => useAxiosRequest<Mixed, null, null, { content: Int }>(`/api/cms/content/${config.path.content}`, 'delete', config);

export const contentDeleteDelete = (ctx: any, path: { content: Int }) => axiosRequest<Mixed>(ctx, `/api/cms/content/${path.content}`, 'delete', null, null);

export const useContentItemGet = (config: RequestConfig<null, null, { content: Int }>) => useAxiosRequest<ContentCmsResponse, null, null, { content: Int }>(`/api/cms/content/${config.path.content}`, 'get', config);

export const contentItemGet = (ctx: any, path: { content: Int }) => axiosRequest<ContentCmsResponse>(ctx, `/api/cms/content/${path.content}`, 'get', null, null);

export const useDatagridGetSchemaGet = (config: RequestConfig<null, null, { name: XString }>) => useAxiosRequest<DataGridSchema, null, null, { name: XString }>(`/api/cms/datagrid/schema/${config.path.name}`, 'get', config);

export const datagridGetSchemaGet = (ctx: any, path: { name: XString }) => axiosRequest<DataGridSchema>(ctx, `/api/cms/datagrid/schema/${path.name}`, 'get', null, null);

export const useDatagridGetAutocompleteGet = (config: RequestConfig<null, DataGridAutocompleteRequestQuery, { name: XString }>) => useAxiosRequest<Array<AutocompleteResult>, null, DataGridAutocompleteRequestQuery, { name: XString }>(`/api/cms/datagrid/autocomplete/${config.path.name}`, 'get', config);

export const datagridGetAutocompleteGet = (ctx: any, path: { name: XString }, query: DataGridAutocompleteRequestQuery) => axiosRequest<Array<AutocompleteResult>>(ctx, `/api/cms/datagrid/autocomplete/${path.name}`, 'get', null, query);

export const useDatagridGetExportGet = (config: RequestConfig<null, DataGridRequest & DataGridExportRequestQuery, { name: XString }>) => useAxiosRequest<Mixed, null, DataGridRequest & DataGridExportRequestQuery, { name: XString }>(`/api/cms/datagrid/export/${config.path.name}`, 'get', config);

export const datagridGetExportGet = (ctx: any, path: { name: XString }, query: DataGridRequest & DataGridExportRequestQuery) => axiosRequest<Mixed>(ctx, `/api/cms/datagrid/export/${path.name}`, 'get', null, query);

export const useDatagridGetDataGet = (config: RequestConfig<null, DataGridRequest, { name: XString }>) => useAxiosRequest<DataGridResponse, null, DataGridRequest, { name: XString }>(`/api/cms/datagrid/${config.path.name}`, 'get', config);

export const datagridGetDataGet = (ctx: any, path: { name: XString }, query: DataGridRequest) => axiosRequest<DataGridResponse>(ctx, `/api/cms/datagrid/${path.name}`, 'get', null, query);

export const useFormAutocompleteGet = (config: RequestConfig<null, FormAutocompleteRequestQuery, { name: XString }>) => useAxiosRequest<Array<AutocompleteResult>, null, FormAutocompleteRequestQuery, { name: XString }>(`/api/cms/form/_autocomplete/${config.path.name}`, 'get', config);

export const formAutocompleteGet = (ctx: any, path: { name: XString }, query: FormAutocompleteRequestQuery) => axiosRequest<Array<AutocompleteResult>>(ctx, `/api/cms/form/_autocomplete/${path.name}`, 'get', null, query);

export const useFormSchemaGet = (config: RequestConfig<null, null, { name: XString }>) => useAxiosRequest<FormSchema, null, null, { name: XString }>(`/api/cms/form/${config.path.name}/schema`, 'get', config);

export const formSchemaGet = (ctx: any, path: { name: XString }) => axiosRequest<FormSchema>(ctx, `/api/cms/form/${path.name}/schema`, 'get', null, null);

export const useFormCreatePost = (config: RequestConfig<null, null, { name: XString }>) => useAxiosRequest<Record<string, Mixed>, null, null, { name: XString }>(`/api/cms/form/${config.path.name}`, 'post', config);

export const formCreatePost = (ctx: any, path: { name: XString }) => axiosRequest<Record<string, Mixed>>(ctx, `/api/cms/form/${path.name}`, 'post', null, null);

export const useFormGetGet = (config: RequestConfig<null, null, { name: XString, id: Int }>) => useAxiosRequest<Record<string, Mixed>, null, null, { name: XString, id: Int }>(`/api/cms/form/${config.path.name}/${config.path.id}`, 'get', config);

export const formGetGet = (ctx: any, path: { name: XString, id: Int }) => axiosRequest<Record<string, Mixed>>(ctx, `/api/cms/form/${path.name}/${path.id}`, 'get', null, null);

export const useFormUpdatePut = (config: RequestConfig<null, null, { name: XString, id: Int }>) => useAxiosRequest<Record<string, Mixed>, null, null, { name: XString, id: Int }>(`/api/cms/form/${config.path.name}/${config.path.id}`, 'put', config);

export const formUpdatePut = (ctx: any, path: { name: XString, id: Int }) => axiosRequest<Record<string, Mixed>>(ctx, `/api/cms/form/${path.name}/${path.id}`, 'put', null, null);

export const useFormRemoveDelete = (config: RequestConfig<null, null, { name: XString, id: Int }>) => useAxiosRequest<Mixed, null, null, { name: XString, id: Int }>(`/api/cms/form/${config.path.name}/${config.path.id}`, 'delete', config);

export const formRemoveDelete = (ctx: any, path: { name: XString, id: Int }) => axiosRequest<Mixed>(ctx, `/api/cms/form/${path.name}/${path.id}`, 'delete', null, null);

export const useApiRestaurantZonePostMealPost = (config: RequestConfig<RestaurantMealRequestBody, null, { restaurant: Int }>) => useAxiosRequest<RestaurantMealResponse, RestaurantMealRequestBody, null, { restaurant: Int }>(`/api/restaurant/${config.path.restaurant}/meal`, 'post', config);

export const apiRestaurantZonePostMealPost = (ctx: any, path: { restaurant: Int }, body: RestaurantMealRequestBody) => axiosRequest<RestaurantMealResponse>(ctx, `/api/restaurant/${path.restaurant}/meal`, 'post', body, null);

export const useApiRestaurantZoneUpdateRestaurantReportSetPaidPost = (config: RequestConfig<null, null, { restaurant: Int, restaurantReport: Int }>) => useAxiosRequest<Bool, null, null, { restaurant: Int, restaurantReport: Int }>(`/api/restaurant/${config.path.restaurant}/report/${config.path.restaurantReport}/set-paid`, 'post', config);

export const apiRestaurantZoneUpdateRestaurantReportSetPaidPost = (ctx: any, path: { restaurant: Int, restaurantReport: Int }) => axiosRequest<Bool>(ctx, `/api/restaurant/${path.restaurant}/report/${path.restaurantReport}/set-paid`, 'post', null, null);

export const useApiRestaurantZoneCreateUpdateRestaurantPost = (config: RequestConfig<RestaurantRequestBody, null, null>) => useAxiosRequest<RestaurantResponse, RestaurantRequestBody, null, null>(`/api/restaurant`, 'post', config);

export const apiRestaurantZoneCreateUpdateRestaurantPost = (ctx: any, body: RestaurantRequestBody) => axiosRequest<RestaurantResponse>(ctx, `/api/restaurant`, 'post', body, null);

export const useApiRestaurantZoneCreateUpdateRestaurantProfilePost = (config: RequestConfig<RestaurantProfileRequestBody, null, null>) => useAxiosRequest<RestaurantProfileResponse, RestaurantProfileRequestBody, null, null>(`/api/restaurant/profile`, 'post', config);

export const apiRestaurantZoneCreateUpdateRestaurantProfilePost = (ctx: any, body: RestaurantProfileRequestBody) => axiosRequest<RestaurantProfileResponse>(ctx, `/api/restaurant/profile`, 'post', body, null);

export const useApiProfileZoneGetProfileMeGet = (config: RequestConfig<null, null, null>) => useAxiosRequest<ProfileResponse, null, null, null>(`/api/profile/me`, 'get', config);

export const apiProfileZoneGetProfileMeGet = (ctx: any) => axiosRequest<ProfileResponse>(ctx, `/api/profile/me`, 'get', null, null);

export const useApiProfileZoneUpdateProfilePost = (config: RequestConfig<ProfileRequestBody, null, null>) => useAxiosRequest<ProfileResponse, ProfileRequestBody, null, null>(`/api/profile`, 'post', config);

export const apiProfileZoneUpdateProfilePost = (ctx: any, body: ProfileRequestBody) => axiosRequest<ProfileResponse>(ctx, `/api/profile`, 'post', body, null);

export const useApiOrderZonePaymentStateGet = (config: RequestConfig<null, null, { payment: Int, paymentState: PaymentState }>) => useAxiosRequest<OrderPaymentResponse, null, null, { payment: Int, paymentState: PaymentState }>(`/api/order/payment/${config.path.payment}/${config.path.paymentState}`, 'get', config);

export const apiOrderZonePaymentStateGet = (ctx: any, path: { payment: Int, paymentState: PaymentState }) => axiosRequest<OrderPaymentResponse>(ctx, `/api/order/payment/${path.payment}/${path.paymentState}`, 'get', null, null);

export const useApiOrderZoneGetDeliveredItemsGet = (config: RequestConfig<null, null, { restaurant: Int }>) => useAxiosRequest<Array<OrderItemResponse>, null, null, { restaurant: Int }>(`/api/order/get-delivered-items/${config.path.restaurant}`, 'get', config);

export const apiOrderZoneGetDeliveredItemsGet = (ctx: any, path: { restaurant: Int }) => axiosRequest<Array<OrderItemResponse>>(ctx, `/api/order/get-delivered-items/${path.restaurant}`, 'get', null, null);

export const useApiOrderZoneOrderItemArchivedPost = (config: RequestConfig<null, null, { orderItem: Int }>) => useAxiosRequest<Bool, null, null, { orderItem: Int }>(`/api/order/item/${config.path.orderItem}/archived`, 'post', config);

export const apiOrderZoneOrderItemArchivedPost = (ctx: any, path: { orderItem: Int }) => axiosRequest<Bool>(ctx, `/api/order/item/${path.orderItem}/archived`, 'post', null, null);

export const useApiOrderZoneOrderItemCanceledByRestaurantPost = (config: RequestConfig<OrderVoucherDetailRequest, null, { orderItem: Int }>) => useAxiosRequest<Bool, OrderVoucherDetailRequest, null, { orderItem: Int }>(`/api/order/item/${config.path.orderItem}/canceled-by-restaurant`, 'post', config);

export const apiOrderZoneOrderItemCanceledByRestaurantPost = (ctx: any, path: { orderItem: Int }, body: OrderVoucherDetailRequest) => axiosRequest<Bool>(ctx, `/api/order/item/${path.orderItem}/canceled-by-restaurant`, 'post', body, null);

export const useApiOrderZoneOrderItemDeliveredPost = (config: RequestConfig<OrderVoucherDetailRequest, null, { orderItem: Int }>) => useAxiosRequest<Bool, OrderVoucherDetailRequest, null, { orderItem: Int }>(`/api/order/item/${config.path.orderItem}/delivered`, 'post', config);

export const apiOrderZoneOrderItemDeliveredPost = (ctx: any, path: { orderItem: Int }, body: OrderVoucherDetailRequest) => axiosRequest<Bool>(ctx, `/api/order/item/${path.orderItem}/delivered`, 'post', body, null);

export const useApiOrderZoneOrderItemsAllArchivedPost = (config: RequestConfig<null, null, { restaurant: Int }>) => useAxiosRequest<Bool, null, null, { restaurant: Int }>(`/api/order/order-items-all-archived/${config.path.restaurant}`, 'post', config);

export const apiOrderZoneOrderItemsAllArchivedPost = (ctx: any, path: { restaurant: Int }) => axiosRequest<Bool>(ctx, `/api/order/order-items-all-archived/${path.restaurant}`, 'post', null, null);

export const useApiOrderZoneOrderItemsAllDeliveredPost = (config: RequestConfig<OrderVoucherDetailRequest, null, null>) => useAxiosRequest<Bool, OrderVoucherDetailRequest, null, null>(`/api/order/order-items-all-delivered`, 'post', config);

export const apiOrderZoneOrderItemsAllDeliveredPost = (ctx: any, body: OrderVoucherDetailRequest) => axiosRequest<Bool>(ctx, `/api/order/order-items-all-delivered`, 'post', body, null);

export const useApiOrderZoneVoucherDetailPost = (config: RequestConfig<OrderVoucherDetailRequest, null, null>) => useAxiosRequest<OrderVoucherDetailResponse, OrderVoucherDetailRequest, null, null>(`/api/order/voucher-detail`, 'post', config);

export const apiOrderZoneVoucherDetailPost = (ctx: any, body: OrderVoucherDetailRequest) => axiosRequest<OrderVoucherDetailResponse>(ctx, `/api/order/voucher-detail`, 'post', body, null);

export const useAdminEmailPreviewGet = (config: RequestConfig<null, AdminRendererMailQuery, null>) => useAxiosRequest<Mixed, null, AdminRendererMailQuery, null>(`/api/admin/email`, 'get', config);

export const adminEmailPreviewGet = (ctx: any, query: AdminRendererMailQuery) => axiosRequest<Mixed>(ctx, `/api/admin/email`, 'get', null, query);

